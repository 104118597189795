<template>
	<div v-if="is_visible" class="project">
		<component v-for="(section, index) in content" :key="index" :is="section.name" :data="section"></component>
	</div>
</template>

<script>
	import ProjectsObject from '@/assets/projects';

	export default {
		name: 'Home',

		data() {
			return {
				project: [],
				content: [],
			}
		},

		computed: {
			is_visible() {
				return typeof this.project !== 'undefined';
			}
		},

		created() {
			let slug = this.$route.params.slug.replaceAll('-', '');
			let project = ProjectsObject[slug];
			this.project = ProjectsObject[slug];

			if ( typeof project === 'undefined' ) {
				this.$router.push( { path: '/404' } );
				return;
			}

			// set dynamic title
			document.title = ProjectsObject[slug].title + ' — Kaluna';
			this.content = project.content;
		},

		mounted() {
			this.$functions.sectionPadding();
		}
	}
</script>